import "./mobilewelcome.css"

import { Link } from "react-router-dom"
import { useContext } from "react"
import MediaQuery from "react-responsive"

export default function MobileWelcome( screenWidth ) {
    
    return (
        
        <div className="mobileWelcome">
           <div className="mobileWelcomeTitle">
                <p>Välkommen till Blockazz Records</p>
            </div>

            <div className="mobileWelcomeHorizontalLine"></div>

            <div className="mobileWelcomeText">
                    
                <p>
                    Blockazz Records är sidan som recenserar och tipsar om både nya och äldre skivor
                    inom Blues, Rock, Jazz och andra genrer. Samtliga skivor kommer från egen samling. Syftet är att 
                    inspirera och göra det lättare för andra att hitta bra musik.

                </p>
                

                <p>
                    Tidigare skrevs sidan av två bröder. Hans har numera valt att sluta skriva.
                    Jag (Claes) fortsätter som vanligt.
                
                </p>
                
                <p>
                    
                    
                </p>
            </div>
            
            
        </div>
    )
}